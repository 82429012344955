import {
  Button,
  Divider,
  Typography,
  Popover,
  Autocomplete,
  TextField,
  IconButton,
  Grid,
  Modal,
  TextareaAutosize,
} from "@mui/material";

import React from "react";
import { makeStyles } from "@mui/styles";

import { Box } from "@mui/system";

// import { Message } from '../../utils/messages';
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
import { DialogContext, AlertContext } from "../../contexts";
import { AlertProps } from "../../utils";
// import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import { ColorTextBox, FileUpload, IsRequired } from "../../components";
import {
  scenarioStateMappingUpdate,
  updateRepoMappedInScenario,
} from "../../utils/apiCall";
import { BootstrapTooltip } from "./statesAndAction";

var callTypes = ["Nifi", "Api", "Service Call"];
var executionType=[];
var renderType=[];

const useStyles = makeStyles((theme) => ({
  Header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    background: theme.palette.background.table,
  },
  Header2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    height: "52px",
    background: theme.palette.background.table,
  },
  wrapperdiv: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "8px 16px 24px 16px",
  },
  actionList: {
    padding: "8px 16px",
    height: "calc(100% - 108px)",
    overflowY: "auto",
    background: theme.palette.background.common,
  },
  actionListItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  wrapperdiv2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 16px",
    background: theme.palette.background.common,
    borderTop: " 1px solid #e0e0e0",
  },
  wrapperdiv3: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "8px 16px 0 16px",
    // borderTop: " 1px solid #e0e0e0",
    background: theme.palette.background.table,
  },
  content: {
    // padding: "16px",
    height: "52px",
    // overflow: "auto",
    background: theme.palette.background.common,
  },
  Button: {
    background: theme.palette.button.background,
    color: theme.palette.button.text,
    "&:hover": {
      backgroundColor: theme.palette.button.hover,
    },
  },
  Popover: {
    background: theme.palette.background.table,
    width: "360px",
    padding: theme.spacing(2),
  },
  icon: {
    width: "30px",
    height: "30px",
    // boxShadow: "0 0 2px rgb(184 184 184)",
    borderRadius: "50%",
    padding: "4px",
    color: theme.palette.text.primary,
  },
  style: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    // bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "8px",
    background: theme.palette.background.table,
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
}));

// const styles = (theme) => ({
//   Popover: {
//     background: theme.palette.background.table,
//   },
// });

export const DrawerAddAction = (props) => {
  console.log(props);
  const classes = useStyles();
  const dialogContext = React.useContext(DialogContext);
  const alertProps = React.useContext(AlertContext);
  const alertMessage = React.useContext(AlertContext);
  // const projectinfo = React.useContext(ProjectInfoContext);
  const [state, setState] = React.useState({
    action: "",
    // idmrole: [],
    apiendpoint: "",
    chooseform: "",
    choosetype: "",
    bgcolor: "",
    icon: "",
    textcolor: "",

    rendername: "",
    actschema: "",
    open: false,
    actpopschema: "",
    executiontype:"",
    rendertype:""
  });
  const [showFields, setShowFields] = React.useState({
    showChooseForm: false,
    showApiendPoint: false,
    showrenderName: false,
    renderFieldText: "Function"
  });
  const [isupdate, setUpdate] = React.useState(false);

  const [action, setAction] = React.useState([]);
  const [focused, setFocused] = React.useState(false);



  const onFocus = () => setFocused(true);

  const onBlur = () => setFocused(false);

  React.useEffect(() => {
    getStatesActionData();
    getChooseType();
    getRenderType();
    getExecutionType();
    getQueueSchema();
    // eslint-disable-next-line
  }, []);

  const getQueueSchema = async () => {
    try {
      let params = {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "queue",
        filter: `queue._id=='${props?.data?.queueid}' && queue.activestatus==true`,
        return_fields: "queue.inputschema[0].inputschema",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config)
        .then((res) => {
          const jsonObj=JSON.parse(res.data.result[0])[0];
          delete jsonObj.entity;
          localStorage.setItem("actschema", JSON.stringify(jsonObj));
          localStorage.setItem("actpopschema", JSON.stringify(jsonObj, null, 2));
          setState({ ...state, actschema: JSON.stringify(JSON.parse(res.data.result[0])[0]), actpopschema: JSON.stringify(JSON.parse(res.data.result[0])[0], null, 2) });
          console.log(res.data.result);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getStatesActionData = async () => {
    try {
      let params = {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "queuestatesaction",
        filter: `queuestatesaction.queueid=='${props?.data?.queueid}'&& queuestatesaction.statesid=='${props?.data?._id}'  && queuestatesaction.activestatus==true`,
        return_fields: "queuestatesaction",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config)
        .then((res) => {
          setAction(res.data.result);
          console.log(res.data.result);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };



  const getChooseType = async () => {
    try {
      let params = {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "CodingMaster",
        filter: `CodingMaster.Type=='CHOOSETYPE'  AND CodingMaster.activestatus==true`,
        return_fields: "{code:CodingMaster.code,display:CodingMaster.display}",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config)
        .then((res) => {
          callTypes = res.data.result;
          console.log(res.data.result);
          console.log(props);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getRenderType=async()=>{
    try {
      let params = {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "CodingMaster",
        filter: `CodingMaster.Type=='RENDERTYPE'  AND CodingMaster.activestatus==true`,
        return_fields: "{id:CodingMaster._id,display:CodingMaster.display}",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config)
        .then((res) => {
          renderType = res.data.result;
        
          console.log(res.data.result);
          console.log(props);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  }

  const getExecutionType = async () => {
    try {
      let params = {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "CodingMaster",
        filter: `CodingMaster.Type=='EXECUTIONTYPE'  AND CodingMaster.activestatus==true`,
        return_fields: "{id:CodingMaster._id,display:CodingMaster.display}",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config)
        .then((res) => {
          executionType = res.data.result;
          setState({
            ...state,executiontype:executionType[0].id
          })
          console.log(res.data.result);
          console.log(props);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };


  const open = Boolean(state.anchorEl);
  const id = open ? "simple-popover" : undefined;

  const OnAddAnotherClicked = (e) => {
    setState({
      ...state, anchorEl: e.currentTarget, isEdit: false,

      actschema: localStorage.getItem("actschema"),
      actpopschema: localStorage.getItem("actpopschema")
    });
  };

  const handleClose = () =>
    setState({
      ...state,
      open: false,
    });

  const handleUpdate = () =>
    setState({
      ...state,
      actschema: state.actpopschema,
      open: false,
    });



  const onChangeState = (key, value) => {
    setState({ ...state, [key]: value });
    // props.updateState("inputschema", deleteItem)
  };

  const onHandleChange = (key, value) => {
    // setState({ ...state, [e.target.name]: e.target.value });
    if (key === "action") {
      setState({ ...state, [key]: value.toUpperCase() });
      setUpdate(true);
    }
    else if(key === "rendertype" && value!=null){
      setState({ ...state, [key]: value.id });
    }
    else if (key === "choosetype" && value!=null) {
      const chooseTypeCode=value.code;
      setState({ ...state, [key]: value.code });
      if (chooseTypeCode.trim().toLowerCase() === "service call" || chooseTypeCode.trim().toLowerCase() === "api" || chooseTypeCode.trim().toLowerCase() === "nifi") {
        setShowFields({
          ...showFields,
          showChooseForm: false,
          showApiendPoint: true,
          showrenderName: false
        })
      }
      else if (chooseTypeCode.trim().toLowerCase() === "function" || chooseTypeCode.trim().toLowerCase() === "component" || chooseTypeCode.trim().toLowerCase() === "route") {
       
        setShowFields({
          ...showFields,
          showChooseForm: false,
          showApiendPoint: false,
          showrenderName: true,
          renderFieldText: capitalizeFirstLetter(chooseTypeCode.trim())
        })
      }
      else {
        
        setShowFields({
          ...showFields,
          showChooseForm: false,
          showApiendPoint: false,
          showrenderName: false
        })
      }

    }
    else if(key === "executiontype"){
      setState({ ...state, [key]: value.id });
    }
    else {
      setState({ ...state, [key]: value });
    }
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return ""; // Handle empty or null strings
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const OnPopupOpen = () => {
    setState({
      ...state,
      open: true
    });
  }
  const onPopoverClose = () => {
    setState({
      ...state,
      isEdit: false,
      anchorEl: null,
      // idmrole: [],
      action: "",
      apiendpoint: "",
      chooseform: "",
      choosetype: "",
      bgcolor: "",
      icon: "",
      textcolor: "",
      rendername: "",
      rendertype:"",

      actschema: "",
      actpopschema: ""
    });
  };
  const onSettingClicked = (e, item) => {
    setState({
      ...state,
      anchorEl: e.currentTarget,
      isEdit: true,
      _key: item?._key,
      actionid: item?.actionid,
      action: item?.action,
      bgcolor: item?.bgcolor,
      icon: item?.icon,
      textcolor: item?.textcolor,
      // idmrole: item?.idmrole,
      apiendpoint: item?.apiendpoint,
      chooseform: item?.chooseform,
      choosetype: item?.choosetype,
      _id: item?._id,
      statesid: props?.data?._id,
      existingAction: item?.action,
      rendername: item?.rendername,
      rendertype: item?.rendertype,
      executiontype: item?.executiontype,
      actschema: item?.stateactionpayload ? JSON.stringify(item.stateactionpayload) : "",
      actpopschema: item?.stateactionpayload ? JSON.stringify(item.stateactionpayload, null, 2) : "",
    });
    const chooseType = item?.choosetype?.trim().toLowerCase() ?? "";

    if (chooseType.trim().toLowerCase() === "service call" || chooseType.trim().toLowerCase() === "api" || chooseType.trim().toLowerCase() === "nifi") {
      setShowFields({
        ...showFields,
        showChooseForm: false,
        showApiendPoint: true,
        showrenderName: false
      })
    }
    else if (chooseType.trim().toLowerCase() === "function" || chooseType.trim().toLowerCase() === "component" || chooseType.trim().toLowerCase() === "route") {
      setShowFields({
        ...showFields,
        showChooseForm: false,
        showApiendPoint: false,
        showrenderName: true,
        renderFieldText: capitalizeFirstLetter(chooseType.trim())
      })
    }
    else {
      setShowFields({
        ...showFields,
        showChooseForm: false,
        showApiendPoint: false,
        showrenderName: false
      })
    }

  };

  const onUpdatepopover = async () => {
    let isaction = state?.action.trim().length === 0 ? true : false;
    let isChoosetype = state?.choosetype.trim().length === 0 ? true : false;
    let isChooseform = state?.chooseform.trim().length === 0 ? true : false;
    let isApiendpoint = state?.apiendpoint.trim().length === 0 ? true : false;
    if (isChoosetype && isChooseform && isApiendpoint && isaction) {
      alertProps.setSnack({
        ...alertProps,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Some field are empty",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    } else {
      let checkAction =
        state?.action === state?.existingAction
          ? true
          : action.filter(
            (list) => list.action.toLowerCase() === state.action.toLowerCase()
          ) > 0
            ? false
            : true;
      if (!checkAction) {
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Action Name already exists",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      } else {
        const result = await updateAction(state);
        if (state?.action !== state?.existingAction) {
          await updateRepoMappedInScenario(state?._id, state?.action, "update");
        }
        await updateActionMappedWithScenarios(props?.data?.queueid, state);

        if (result != "fail") {
          onPopoverClose();
        }
      }
      // updateAction(state);
      // onPopoverClose();
    }
  };

  const updateActionbyState = (action, newState) => {
    // debugger;
    let updateaction = action?.map((l) =>
      l._id === newState?._id
        ? {
          ...l,
          action: newState?.action,
          bgcolor: newState?.bgcolor,
          icon: newState?.icon,
          textcolor: newState?.textcolor,
          apiendpoint: newState?.apiendpoint,
          chooseform: newState?.chooseform,
          choosetype: newState?.choosetype,
        }
        : l
    );
    return updateaction ?? [];
  };

  const getState = (state, newState) => {
    // console.log("state", state, newState,id);

    let list = state?.map((g) =>
      g?._id === newState?.statesid
        ? {
          ...g,
          action: updateActionbyState(g?.action, newState),
        }
        : g
    );
    return list;
  };

  const actionName = (actionlist, state, text) => {
    let actname =
      actionlist?.find((l) => l._id === state?._id).action === text
        ? state?.action
        : text;
    return actname;
  };

  const updateWorkflow = (wflow, newState) => {
    let wflw = wflow?.map((l) =>
      l?.data?.value?.status?._id === newState?.statesid
        ? {
          ...l,
          data: {
            ...l?.data,
            ...(l?.data?.text && {
              text:
                l?.data?.actionState === "true"
                  ? actionName(
                    l?.data?.value?.status?.action,
                    newState,
                    l?.data?.text
                  )
                  : l?.data?.text,
            }),
            value: {
              status: {
                ...l?.data?.value?.status,
                action: updateActionbyState(
                  l?.data?.value?.status?.action,
                  newState
                ),
              },
            },
          },
        }
        : l
    );
    return wflw;
  };

  const updateActionMappedWithScenarios = async (queueid, state) => {
    try {
      let params = {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "queuescenariostatemapping",
        filter: `queuescenariostatemapping.queueid=='${queueid}' && queuescenariostatemapping.activestatus==true`,
        return_fields: "queuescenariostatemapping",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      const res = await axios(config);

      let mappedScenario = res?.data?.result;

      if (mappedScenario?.length > 0) {
        let mappedNewWithState = mappedScenario?.map((l) => {
          let list = {
            ...l,
            states: getState(l.states, state),
            workflowdiagram: updateWorkflow(l.workflowdiagram, state),
          };
          return list;
        });

        let responeList = mappedNewWithState.map(
          async (l) => await scenarioStateMappingUpdate(l)
        );

        return responeList;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateAction = async (value) => {
    const renderName = value?.rendername;
    if(value.rendertype!=null && value.rendertype!=undefined){
      if (renderName == null || renderName.trim() == "") {
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Render Name should not be empty!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        return "fail";
      }
    }
    if (value.choosetype.toLowerCase() == "function" || value.choosetype.toLowerCase() == "component") {
      if (renderName == null || renderName.trim() == "") {
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Render Name should not be empty!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        return "fail";
      }
    }


    let datas = {
      queueid: props?.data?.queueid,
      statesid: props?.data?._id,
      actionid: value?.actionid,
      action: value?.action,
      bgcolor: value?.bgcolor,
      icon: value?.icon,
      textcolor: value?.textcolor,
      // idmrole: value?.idmrole,
      apiendpoint: value?.apiendpoint,
      chooseform: value?.chooseform,
      choosetype: value?.choosetype,
      executiontype:value?.executiontype,
      // isEdit: false,
      version: "",
      active: true,


      rendername: value?.rendername,
      rendertype: value?.rendertype,
      stateactionpayload: value?.actschema ? JSON.parse(value.actschema) : {},

    };
    try {
      let params = [
        {
          db_name: `${localStorage.getItem("ProjectDbname")}`,
          entity: "queuestatesaction",
          filter: {
            _key: `${value._key}`,
          },
          is_metadata: true,
          metadataId: `${localStorage.getItem("metaId")}`,
          metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
          doc: datas,
        },
      ];
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_UPSERT}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config).then((res) => {
        if (res.data.Code === 201) {
          getStatesActionData();
          props?.getStatesData();
          alertMessage?.setSnack({
            ...alertMessage,
            open: true,
            severity: AlertProps.severity.success,
            msg: "Action Updated Successfully",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
          setUpdate(false);
        } else if (res.data.Code === 1111) {
          alertMessage?.setSnack({
            ...alertMessage,
            open: true,
            severity: AlertProps.severity.error,
            msg: `${res?.data?.error ?? "Something Went Wrong"}`,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        } else {
          alertMessage?.setSnack({
            ...alertMessage,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Something Went Wrong",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        }

        // console.log(res);
      });
    } catch (error) {
      console.log(error);
    }
  };
  const onDialogOpen = (_key, _id) => {
    dialogContext.setDialog({
      ...dialogContext,
      open: true,
      title: "Are you sure you want to delete this action?",
      body: "",
      positiveBtn: "Confirm",
      negativeBtn: "Cancel",
      onOk: () => onDeletedClicked(_key, _id),
    });
  };

  const onDeletedClicked = (_key) => {
    dialogContext.setDialog({
      ...dialogContext,
      open: false,
    });
    statesSoftDelete(_key);
  };
  const statesSoftDelete = async (_key) => {
    try {
      let params = {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "queuestatesaction",
        filter: `queuestatesaction._key=='${_key}'`,
        metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_SOFTDELETE}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config).then(async (res) => {
        let _id = res?.data?.Result[0]?.doc?._id;

        await updateRepoMappedInScenario(_id, "", "softDelete");
        await getStatesActionData();
        props?.getStatesData();
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Action Deleted Successfully",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        // console.log(res);
      });
    } catch (error) {
      console.log(error);
    }
  };
  const onPopSave = () => {
    let isaction = state?.action.trim().length === 0 ? false : true;
    // let isChoosetype = state?.choosetype.trim().length === 0 ? false : true;
    // let isChooseform = state?.chooseform.trim().length === 0 ? false : true;
    // let isApiendpoint = state?.apiendpoint.trim().length === 0 ? false : true;
    if (isaction) {
      let checkAction = action.filter(
        (list) => list.action.toLowerCase() === state.action.toLowerCase()
      );
      if (checkAction.length !== 0) {
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Action Name already exists",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      } else {
        upsertAction(state);
      }
    } else {
      // console.log(state);
      alertProps.setSnack({
        ...alertProps,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Required fields are empty",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    }
  };
  const upsertAction = async (value) => {


    const renderName = value?.rendername;

    if(value.rendertype!=null && value.rendertype!=undefined){
      if (renderName == null || renderName.trim() == "") {
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Render Name should not be empty!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        return "fail";
      }
    }
    
    if (value.choosetype.toLowerCase() == "function" || value.choosetype.toLowerCase() == "component") {
      if (renderName == null || renderName.trim() == "") {
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Render Name should not be empty!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        return "fail";
      }
    }


    let datas = {
      queueid: props?.data?.queueid,
      statesid: props?.data?._id,
      actionid: "",
      action: value?.action,
      bgcolor: value?.bgcolor,
      icon: value?.icon,
      textcolor: value?.textcolor,
      // idmrole: value?.idmrole,
      apiendpoint: value?.apiendpoint,
      chooseform: value?.chooseform,
      choosetype: value?.choosetype,


      rendername: value?.rendername,
      stateactionpayload: value?.actschema ? JSON.parse(value.actschema) : {},
      executiontype:value?.executiontype,
      // isEdit: false,
      version: "",
      active: true,
      rendertype: value?.rendertype,
    };

    try {
      let params = [
        {
          db_name: `${localStorage.getItem("ProjectDbname")}`,
          entity: "queuestatesaction",
          is_metadata: true,
          metadataId: `${localStorage.getItem("metaId")}`,
          metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
          doc: datas,
        },
      ];
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_UPSERT}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config).then((res) => {
        if (res.data.Code === 201) {
          alertMessage?.setSnack({
            ...alertMessage,
            open: true,
            severity: AlertProps.severity.success,
            msg: "Action Added Successfully",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
          getStatesActionData();
          onPopoverClose();
          // console.log(res);
          props?.getStatesData();
        } else if (res.data.Code === 1111) {
          alertMessage?.setSnack({
            ...alertMessage,
            open: true,
            severity: AlertProps.severity.error,
            msg: `${res?.data?.error ?? "Something Went Wrong"}`,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        } else {
          alertMessage?.setSnack({
            ...alertMessage,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Something Went Wrong",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ height: "100vh" }}>
      <Box className={classes.Header2}>
        <Typography variant="body1">Add Action</Typography>
      </Box>
      <Divider />
      <div className={classes.actionList}>
        {action?.map((item, index) => (
          <div key={index}>
            <div className={classes.actionListItem}>
              <Typography variant="body2">{item.action}</Typography>
              <div>
                <IconButton
                  id={"Settings Icon"}
                  onClick={(e) => onSettingClicked(e, item)}
                >
                  <SettingsOutlinedIcon className={classes.icon} />
                </IconButton>
                <IconButton
                  id={"Delete Icon"}
                  className={classes.icon}
                  onClick={(e) => onDialogOpen(item?._key)}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>

            <Divider />
          </div>
        ))}
        <Button
          onClick={(e) => OnAddAnotherClicked(e)}
          variant="text"
          style={{ color: "#3495FF", marginTop: "8px" }}
        >
          {action?.length === 0 ? "+ Add Action" : "+Add Another Action"}
        </Button>
      </div>
      <div className={classes.content}>
        <Divider />
        <div className={classes.wrapperdiv2}>
          <Button onClick={() => props.onDrawerClosed()} variant="outlined">
            {"Cancel"}
          </Button>
          {/* <Button onClick={() => onSaveClicked()} variant='contained' sx={{ ml: "16px" }} >
                    {"Save"}
                </Button> */}
        </div>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={state?.anchorEl}
        onClose={onPopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Grid className={classes.Popover}>
          <Grid item xs={12}>
            <Typography variant="body2" gutterBottom>
              {"Action "}
              <IsRequired />
            </Typography>
            <BootstrapTooltip
              title="If you are trying to update the action name,Please check the scenarios state's action mapping, workflow and its rule configuration"
              open={state?.isEdit && focused}
            >
              <TextField
                id="outlined-name"
                // label="Enter State Name"
                name="action"
                value={state?.action}
                autoComplete="off"
                onChange={(e) => onHandleChange("action", e.target.value)}
                placeholder="Enter Action Name"
                fullWidth
                onFocus={onFocus}
                onBlur={onBlur}
                size="small"
              // margin="dense"
              // error={state?.errorStatesName}
              // helperText={state?.errorStatesName === true ? Message.requiredMessage : ""}
              />
            </BootstrapTooltip>
          </Grid>
          {/* <Grid item xs={12} sx={{ py: 1 }}>
            <Typography variant="body2" gutterBottom>
              {"Role"}
            </Typography>
            <Autocomplete
              id="choosetype"
              options={JSON.parse(sessionStorage.getItem("userGroup")) ?? []}
              getOptionLabel={(option) => option.name}
              value={state?.idmrole ?? []}
              fullWidth
              name="choosetype"
              // filterSelectedOptions
              multiple
              size="small"
              onChange={(e, value) => onHandleChange("idmrole", value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  fullWidth
                  size="small"
                  name="idmrole"
                />
              )}
            />
          </Grid> */}
          <Grid item xs={12} sx={{ mt: 1 }}>
            <Typography variant="body2" gutterBottom>
              {"Choose Type"}
            </Typography>
            <Autocomplete
              id="choosetype"
              options={callTypes ?? []}
              getOptionLabel={(option) => option.display}
              value={callTypes.find((item) => item.code === state?.choosetype) || null}
             
              fullWidth
              name="choosetype"
              // style={{ color: "#000" }}
              // PopperComponent={<Popper {...props} style={styles.popper} />}
              onChange={(e, value) => onHandleChange("choosetype", value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  fullWidth
                  size="small"
                  // margin="dense"
                  name="choosetype"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sx={{ mt: 1 }}>
            <Typography variant="body2"
              style={{ float: 'left' }}
              gutterBottom>
              {"Action Schema"}
            </Typography>
            {state.actschema ? (
              <Button
                id={"View Schema"}
                onClick={() => OnPopupOpen()}
                variant="outlined"
                size="small"
                sx={{
                  fontSize: '0.7rem',
                  padding: '4px 8px',
                  minWidth: '64px',
                }}
                style={{ float: 'left', marginLeft: '10px' }}
              >
                +Update
              </Button>) : (

              <Button
                id={"View Schema"}
                onClick={() => OnPopupOpen()}
                variant="outlined"
                size="small"
                sx={{
                  fontSize: '0.7rem',
                  padding: '4px 8px',
                  minWidth: '64px',
                }}
                style={{ float: 'left', marginLeft: '10px' }}
              >
                +Add
              </Button>
            )
            }

            <TextField
              id="outlined-name"
              // label="Enter State Name"
              name="actschema"
              value={state?.actschema}
              onChange={(e) => onHandleChange("actschema", e.target.value)}
              placeholder="Enter schema"
              fullWidth
              autoComplete="off"
              size="small"
              margin="dense"
              disabled="true"
            // error={state?.errorStatesName}
            // helperText={state?.errorStatesName === true ? Message.requiredMessage : ""}
            />

          </Grid>

          {
            showFields.showChooseForm && (
              <Grid item xs={12} sx={{ mt: 1 }}>
                <Typography variant="body2" gutterBottom>
                  {"Choose Form"}
                </Typography>
                <TextField
                  id="outlined-name"
                  // label="Enter State Name"
                  name="chooseform"
                  autoComplete="off"
                  value={state?.chooseform}
                  onChange={(e) => onHandleChange("chooseform", e.target.value)}
                  placeholder="Search"
                  fullWidth
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  margin="dense"
                // error={state?.errorStatesName}
                // helperText={state?.errorStatesName === true ? Message.requiredMessage : ""}
                />
              </Grid>)
          }

          {
            showFields.showApiendPoint && (<Grid item xs={12} sx={{ mt: 1 }}>
              <Typography variant="body2" gutterBottom>
                {"API End Point"}
              </Typography>
              <TextField
                id="outlined-name"
                // label="Enter State Name"
                name="apiendpoint"
                value={state?.apiendpoint}
                onChange={(e) => onHandleChange("apiendpoint", e.target.value)}
                placeholder="Enter apiendpoint"
                fullWidth
                autoComplete="off"
                size="small"
                margin="dense"
              // error={state?.errorStatesName}
              // helperText={state?.errorStatesName === true ? Message.requiredMessage : ""}
              />
            </Grid>)
          }


<Grid item xs={12} sx={{ mt: 1 }}>
            <Typography variant="body2" gutterBottom>
              {"Render Type"}
            </Typography>
            <Autocomplete
              id="rendertype"
              options={renderType ?? []}
              getOptionLabel={(option) => option.display}
              value={renderType.find((item) => item.id === state?.rendertype) || null}
             
              fullWidth
              name="rendertype"
              // style={{ color: "#000" }}
              // PopperComponent={<Popper {...props} style={styles.popper} />}
              onChange={(e, value) => onHandleChange("rendertype", value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  fullWidth
                  size="small"
                  // margin="dense"
                  name="rendertype"
                />
              )}
            />
          </Grid>

          
          <Grid item xs={12} sx={{ mt: 1 }}>
            <Typography variant="body2" gutterBottom>
             {"Render Name"}
            </Typography>

            <TextField
              id="outlined-name"
              // label="Enter State Name"
              name="rendername"
              value={state?.rendername}
              onChange={(e) => onHandleChange("rendername", e.target.value)}
              placeholder={`Enter Render Name`}
              fullWidth
              autoComplete="off"
              size="small"
              margin="dense"
            // error={state?.errorStatesName}
            // helperText={state?.errorStatesName === true ? Message.requiredMessage : ""}
            />
          </Grid>
          

<Grid item xs={12} sx={{ mt: 1 }}>
            <Typography variant="body2" gutterBottom>
              {"Execution Type"}
            </Typography>
            <Autocomplete
              id="executiontype"
              options={executionType ?? []}
              getOptionLabel={(option) => option.display}
              value={executionType.find((item) => item.id === state?.executiontype) || null}
             
              fullWidth
              name="executiontype"
              // style={{ color: "#000" }}
              // PopperComponent={<Popper {...props} style={styles.popper} />}
              onChange={(e, value) => onHandleChange("executiontype", value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  fullWidth
                  size="small"
                  // margin="dense"
                  name="executiontype"
                />
              )}
            />
          </Grid>


          <Grid item lg={12} md={12} xs={12}>
            <Typography variant="body2" gutterBottom>
              {"Background Color"}
            </Typography>

            <ColorTextBox
              value={state?.bgcolor}
              onHandleChange={(data) => onHandleChange("bgcolor", data)}
            />
          </Grid>

          <Grid item lg={12} md={12} xs={12}>
            <Typography variant="body2" gutterBottom>
              {"Text Color"}
            </Typography>
            <ColorTextBox
              value={state?.textcolor}
              onHandleChange={(data) => onHandleChange("textcolor", data)}
            />
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <Typography variant="body2" gutterBottom>
              State Icon
            </Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              <FileUpload changeState={onHandleChange} label={"icon"} />
              {state?.icon && (
                <img
                  width="20"
                  height="20"
                  style={{ marginLeft: "8px", marginTop: "2px" }}
                  src={state?.icon}
                  alt={"Scenario Icon"}
                />
              )}
            </div>
          </Grid>

          <div className={classes.wrapperdiv3}>
            <Button
              id={"Cancel Button"}
              onClick={() => onPopoverClose()}
              variant="outlined"
            >
              {"Cancel"}
            </Button>
            {state?.isEdit ? (
              <Button
                id={"Update Button"}
                onClick={() => onUpdatepopover()}
                variant="contained"
                className={classes.Button}
                sx={{ ml: "16px" }}
              >
                {"Update"}
              </Button>
            ) : (
              <Button
                id={"Save Button"}
                onClick={() => onPopSave()}
                variant="contained"
                className={classes.Button}
                sx={{ ml: "16px" }}
              >
                {"Save"}
              </Button>
            )}
          </div>
        </Grid>
      </Popover>


      <Modal
        open={state.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.style}>
          <Box className={classes.Header}>
            <Typography variant="body1">
              {state.isEdit ? "Update Schema" : "Add Schema"}
            </Typography>
          </Box>
          <Divider />
          <div className={classes.divScroll}>
            <Grid container direction="row">

              <Grid item xs={12} sx={{ p: 2 }}>
                <Typography variant="body1"> Schema </Typography>

                <TextareaAutosize
                  aria-label="minimum height"
                  value={state?.actpopschema}
                  onChange={(e) =>
                    onChangeState("actpopschema", e.target.value)
                  }
                  className={classes.textArea}
                  placeholder="Type Here"
                  style={{
                    width: "100%",
                    borderColor: "#b3b3b3",
                    marginTop: "8px",
                    borderRadius: "5px",
                    padding: "8.5px 14px",
                    resize: "vertical",
                  }}
                  minRows={10}
                // error={error?.isinputSchema}
                // helperText={error?.isinputSchema ? "This Field is Required*" : ""}
                />
              </Grid>
            </Grid>
          </div>

          <Divider />
          <div className={classes.wrapperdiv}>
            <Button
              id={"Cancel Button"}
              onClick={() => handleClose()}
              variant="outlined"
            >
              {"Cancel"}
            </Button>
            {state.actschema ? (
              <Button
                id={"Update Button"}
                onClick={() => handleUpdate()}
                variant="contained"
                sx={{ ml: "16px" }}
                className={classes.Button}
              >
                {"Update"}
              </Button>
            ) : (
              <Button
                id={"Add Button"}
                onClick={() => handleUpdate()}
                variant="contained"
                sx={{ ml: "16px" }}
                className={classes.Button}
              >
                {"Add"}
              </Button>
            )}
          </div>
        </Box>
        {/* <AddEditState handleClose={handleClose} updateStatedata={updateStatedata} /> */}
      </Modal>


    </div>
  );
};
